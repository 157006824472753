// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SmashcutPhoneInput--phoneInput--1qa1Ez12{border:none !important;border-bottom:1px solid black !important;border-radius:0 !important;background:transparent !important}.SmashcutPhoneInput--phoneInputContainer--SPpg5tAu:after{content:'*';color:red}.SmashcutPhoneInput--phoneInputContainer--SPpg5tAu .SmashcutPhoneInput--phoneError--3jV98Bjl{border-bottom-color:#8900e1 !important}.SmashcutPhoneInput--phoneInputContainer--SPpg5tAu .country-name{color:#000000}.SmashcutPhoneInput--phoneInputContainer--SPpg5tAu .flag-dropdown{background:none !important;border:none !important;border-radius:0 !important;bottom:1px !important}.SmashcutPhoneInput--phoneInputContainer--SPpg5tAu .flag-dropdown.open-dropdown{background:transparent}.SmashcutPhoneInput--phoneInputContainer--SPpg5tAu .flag-dropdown.open-dropdown .selected-flag{background:transparent}.SmashcutPhoneInput--phoneInputContainer--SPpg5tAu .flag-dropdown:hover .selected-flag{background-color:transparent}.SmashcutPhoneInput--phoneErrorMessage--QykIgZH8{color:#eb5640;font-size:12px;margin-top:3px}\n", ""]);
// Exports
exports.locals = {
	"phoneInput": "SmashcutPhoneInput--phoneInput--1qa1Ez12",
	"phoneInputContainer": "SmashcutPhoneInput--phoneInputContainer--SPpg5tAu",
	"phoneError": "SmashcutPhoneInput--phoneError--3jV98Bjl",
	"phoneErrorMessage": "SmashcutPhoneInput--phoneErrorMessage--QykIgZH8"
};
module.exports = exports;
