import Logo from './header_logo_new.png';
import React from 'react';
import styles from './LoginPageLogo.scss';

const LoginPageLogo = ({ testLogo }) => (
  <div className={styles.loginPageLogo}>
    <img src={testLogo || Logo} alt="NYU Logo" />
  </div>
);

export default LoginPageLogo;
