// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".HelpChatButton--helpChat--2h3Z7Urv{margin:0 10px;text-transform:uppercase;background:#ffffff !important}.HelpChatButton--helpChat--2h3Z7Urv span{color:#8900e1 !important}.HelpChatButton--helpChat--2h3Z7Urv i{color:#8900e1 !important;font-size:16px}@media (max-width: 1000px){.HelpChatButton--helpChat--2h3Z7Urv{display:none}}\n", ""]);
// Exports
exports.locals = {
	"helpChat": "HelpChatButton--helpChat--2h3Z7Urv"
};
module.exports = exports;
