'use strict';

import baseConfig from './base';

const awsApi = 'https://qa.api.smashcut.com';
const awsWss = 'wss://ln2n2rhie6.execute-api.eu-west-1.amazonaws.com/qa';

let config = {
  apolloUri: `${awsApi}/graphql`,
  appEnv: 'qa',
  auth0Enabled: false,
  auth0ClientId: 'aIAiJ9yzAfI40gOaNxUaVssHLKntf8eL',
  auth0Domain: 'dev-auth.smashcut.com',
  baseUrl: 'https://qa.smashcut.com',
  camSharingEnabled: true,
  logrocket: 'px1cem/smashcut-web-qa',
  logRocketEnabled: true,
  opentokApiKey: '47015444',
  pushMessagingPublicVapidKey:
    'BLTdj-VzRW6ncSY-c3VQOyrN2NrNymEaBM0vTk6MV8iHR3wDu2fWlXAXRLD8zaM-pqFCw1lqVADiVpVeb4pKdFY',
  reduxLoggerEnabled: false,
  sentry:
    'https://d527facc0c9f424fad539428fa66ccae@o82327.ingest.sentry.io/179641',
  sentryEnabled: true,
  smashcutApiUrl: awsApi,
  wssUrl: awsWss
};

let mergedConfig = Object.freeze(Object.assign({}, baseConfig, config));
console.log('Using config: ', mergedConfig);
export default mergedConfig;
