export const footerLinks = {
  copyrightOwner: 'NYU Tisch',
  facebookUrl: 'https://www.facebook.com/tischsp',
  instagramUrl: 'https://www.instagram.com/tischsp/',
  mediumUrl: '',
  privacyPolicyUrl: 'https://www.smashcut.com/privacy',
  termsOfServiceUrl: 'https://www.smashcut.com/terms',
  twitterUrl: 'https://twitter.com/tischsp',
  vimeoUrl: 'https://vimeo.com/tischsp',
  youtubeUrl: 'https://www.youtube.com/user/TischSpecialPrograms?reload=9'
};

export const welcomeName = 'NYU';

export const getInTouch = `If you have questions, you can get in touch
      with a student advisor by emailing help@smashcut.com or by clicking the
      "Help Chat" button in the header of any Smashcut webpage.`;

export const helpCenterUrl = 'https://intercom.help/nyu-programs';

export const highlightColor = '#8900e1';

export const showDisciplines = false;

export const showProgramStartDate = true;

export const useScreenplayForPdfUploads = false;

export const showLms = true;

export const blockMobileAccess = false;

export const topNavLinks = {
  theme: 'nyu',
  blog: {
    blogComponent: false,
    blogUrl: 'https://medium.com/smashcut-film'
  },
  helpCenter: {
    helpCenterKeyMentor: 'help center',
    helpCenterTitleMentor: 'Help Center',
    helpCenterUrlMentor: 'https://intercom.help/nyu-programs',
    helpCenterKey: 'help center',
    helpCenterTitle: 'Help Center',
    helpCenterUrl: 'https://intercom.help/nyu-programs'
  },
  availability: {
    availabilityComponent: false
  },
  browseCourses: true
};

export const requiredAccountDetailsFields = {};

export const preStartModules = {
  welcomeVideo: true,
  introduceYourself: false
};

export const peoplePerPageInConferenceGridView = 20;

export const enrollEnabled = true;

export const climbcreditEnabled = false;

export const videoMeetingArchivingEnabled = true;
